import React from 'react'

import { Col, Divider, Inline, Row, Text } from '@te-digi/styleguide'

import { Playground } from '../../components/Playground'
import { Content } from '../../components/Content'
import { Section } from '../../components/Section'

// HUOM! lead-teksti määritellään pages.ts -tiedostossa
const Page = () => (
  <Content
    heading="Divider"
    components={[{ component: Divider }]}
  >
    <Section>
      <Playground>
        <Divider />
      </Playground>
      <Playground>
        <Divider variant="sidebar" />
      </Playground>
      <Playground format="html">
        <div className="divider" />
      </Playground>
    </Section>
    <Section title="Vertikaalinen">
      <Playground>
        <Row>
          <Col>
            <Text align="center">
              Lorem ipsum
              <br />
              Dolor sit amet
            </Text>
          </Col>
          <Col xs="auto">
            <Divider
              noMargin
              variant="vertical"
            />
          </Col>
          <Col>
            <Text align="center">
              Lorem ipsum
              <br />
              Dolor sit amet
            </Text>
          </Col>
        </Row>
      </Playground>
      <Playground format="html">
        <div className="row">
          <div className="col text-center">
            Lorem ipsum
            <br />
            Dolor sit amet
          </div>
          <div className="col-auto">
            <div className="divider-vertical m-0" />
          </div>
          <div className="col text-center">
            Lorem ipsum
            <br />
            Dolor sit amet
          </div>
        </div>
      </Playground>
    </Section>
    <Section title="Inline vertical">
      <Playground>
        <Inline>
          <Text>Vasen</Text>
          <Divider variant={'inlineVertical'} />
          <Text>Oikea</Text>
        </Inline>
      </Playground>
    </Section>
  </Content>
)

export default Page
